import {
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import {
  BehaviorSubject,
  Observable,
  filter,
  switchMap,
} from 'rxjs';
import { ConfigService, IConfig } from '@app/services';

import { HttpLink } from 'apollo-angular/http';
import { Injectable } from '@angular/core';
import { reportInternalError } from '@app/utils';
import { setContext } from '@apollo/client/link/context';

@Injectable({
  providedIn: 'root'
})
export class ApolloOptionsService {
  apolloOptionSub = new BehaviorSubject<any>(undefined);
  config$ = new BehaviorSubject<IConfig | undefined>(undefined);

  constructor(
    private readonly httpLink: HttpLink,
    private readonly configSvc: ConfigService) {
  }

  getApolloOtionsValue(): any {
    return this.apolloOptionSub.getValue();
  }

  createApollo$(): Observable<any> {
    return this.configSvc.getConfig$()
      .pipe(
        filter(val => !!val),
        switchMap((config: IConfig) => this.createApolloOtions$(config))
      );
  }

  private createApolloOtions$(config: IConfig): Observable<any> {
    const basic = setContext((operation, context) => ({
      headers: {
        Accept: 'charset=utf-8',
      },
    }));

    // Bind the function to the class instance
    const uri = config.atdGraphqlUri;
    let link;
    try {
      if (uri !== undefined && uri !== '') {
        link = ApolloLink.from([basic, this.httpLink.create({ uri })]);
      }
      else {
        reportInternalError('Config service has no URI:');
        link = ApolloLink.from([basic]);
      }
    } catch (error) {
      // Assign a default value.
      reportInternalError(`Error creating ApolloLink: ${error}`);
      link = ApolloLink.empty();
    }

    /*
      Because we do not use id as our identifier for our atds, we have to
      let the cache know what it is.When the atd is updated, it now updates
      the in memory cache for that item and renders the correct version of
      that atd (so we are still using the cache, it is just using the
      correct reference now)
      https://the-guild.dev/graphql/apollo-angular/docs/caching/configuration
      - Atd key identifier is 'tenantIdId'
    */
    const cache = new InMemoryCache({
      typePolicies: {
        Atd: {
          keyFields: ['tenantIdId'],
        }
      }
    });
    const options = {
      link,
      cache,
    };

    this.apolloOptionSub.next(options);

    return this.apolloOptionSub.asObservable();
  }
}
