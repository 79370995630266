/*
 * Error text
 */

export const ERR_NO_OUTER_DATA = 'API service returned no outer data';
export const ERR_NO_FUNCTION_RESULT =
  'API service returned no result for function: ';
export const ERR_NO_STATUS_CODE = 'API service returned no status code';
export const ERR_NO_INNER_DATA = 'API service returned no inner data';
export const ERR_UNRECOGNIZED_STATUS = 'unrecognized status: ';

/*
 * errors from the API backend
 */

export class ApiServiceError extends Error {}

// A 4xx error from the API
export class ApiClientError extends ApiServiceError {
  code: string;
  status: number;
  summary: string;

  constructor(status: number, code: string, summary: string, message: string) {
    super(message);
    this.name = 'ApiClientError';
    this.status = status;
    this.code = code;
    this.summary = summary;
  }
}

// A 5xx error from the API
export class ApiServerError extends ApiServiceError {
  code: string;
  status: number;
  summary: string;

  constructor(status: number, code: string, summary: string, message: string) {
    super(message);
    this.name = 'ApiServerError';
    this.status = status;
    this.code = code;
    this.summary = summary;
  }
}

/*
 * errors from the API parser
 */

export class ParserError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ParserError';
  }
}

/*
 * errors from the data service
 */

export class DataServiceError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DataServiceError';
  }
}
