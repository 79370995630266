/*
 * This class is responsible for obtaining all configuration information.
 * It obtains dynamic data from the config.json file on the server (or
 * from local-config.json when running locally).
 * It obtains static data from the environment.ts file (or from
 * environment.local.ts) when running locally).
 */
import { Constants, reportInternalError } from '@app/utils';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  map,
  of,
  shareReplay,
  takeUntil,
  tap,
} from 'rxjs';

import { ApiService } from './api/api.service';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env/environment';

export interface IConfig {
  audience: string;
  client: string;
  domain: string;
  environment: string;
  timestamp: string;
  atdGraphqlUri: string;
  awsRumGuestRoleArn: string;
  awsRumApplicationId: string;
  awsRumIdentityPoolId: string;
  awsRumEndpoint: string;
  dataAssetsUrl: string;
  notificationGraphqlUri: string;
  notificationWebsocketUri: string;
}

export type FeatureFlags = { [key: string]: boolean };

@Injectable()
export class ConfigService implements OnDestroy {
  private readonly authClientConfig: AuthClientConfig;
  private readonly jsonFile: string;
  private readonly configSubject = new ReplaySubject<IConfig>(1);
  private readonly unsubscribe = new Subject<void>();
  private apiService: ApiService | null = null;
  private dynamicFlags$: Observable<FeatureFlags> | undefined = undefined;

  constructor(
    private readonly httpClient: HttpClient,
    authClientConfig: AuthClientConfig,
    private readonly injector: Injector,
  ) {
    this.authClientConfig = authClientConfig;
    this.jsonFile = `assets/${env.production ? '' : 'local-'}config.json`;

    this.load$()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.configSubject.next(res);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Sets up an observable stream for downstream
   * componets/services to recieve config values.
   * @returns IConfig obeservable stream.
   */
  getConfig$(): Observable<IConfig> {
    return this.configSubject.asObservable();
  }

  /**
   * Makes a http call to get configuration settings
   * from a local json file.
   * @returns IConfig interface with all the settings.
   */
  protected load$(): Observable<IConfig> {
    return this.httpClient.get<IConfig>(this.jsonFile).pipe(
      tap((res) => {
        this.authClientConfig.set({
          domain: res.domain,
          clientId: res.client,
          errorPath: '/error',
          authorizationParams: {
            redirect_uri: window.location.origin,
            ui_locales: 'en',
            audience: res.audience,
          },
        });
      }),
    );
  }

  isFeatureEnabled$(
    featureName: string,
    valueIfFeatureMissing: boolean,
  ): Observable<boolean> {
    // Lazily inject the ApiService to prevent a circular dependency
    if (!this.apiService) {
      this.apiService = this.injector.get(ApiService);
    }
    if (!this.dynamicFlags$) {
      // fetch flags, update cache
      this.dynamicFlags$ = this.apiService
        .getFeatureFlags$(Constants.featureFlagIds)
        .pipe(
          catchError((err: Error) => {
            reportInternalError(err.message);
            return of({});
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
    return this.dynamicFlags$.pipe(
      map((dynamicFlags) => {
        if (featureName in dynamicFlags) {
          return dynamicFlags[featureName];
        }

        const features: { [key: string]: boolean } = env?.features;
        return features?.[featureName] ?? valueIfFeatureMissing;
      }),
    );
  }
}
