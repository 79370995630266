import { APOLLO_OPTIONS, Apollo, ApolloModule } from 'apollo-angular';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { filter, lastValueFrom, take } from 'rxjs';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { ApolloOptionsService } from '@app/services';

export function createApollo(apolloOptionsService: ApolloOptionsService) {
  return async (): Promise<any> => {
    return await lastValueFrom(apolloOptionsService.createApollo$()
      .pipe(filter(val => !!val))
      .pipe(take(1)));
  };
}

export function getApolloOptions(apolloOptionsService: ApolloOptionsService) {
  return apolloOptionsService.getApolloOtionsValue();
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    ApolloOptionsService,
    {
      provide: APP_INITIALIZER,
      useFactory: createApollo,
      deps: [ApolloOptionsService],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: getApolloOptions,
      deps: [ApolloOptionsService],
    },
    Apollo,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class GraphQLModule {}
