import {
  APP_INITIALIZER,
  Injectable,
  NgModule,
} from '@angular/core';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { ConfigService, IConfig } from '@app/services';
import { environment as env } from '@env/environment';
import { filter } from 'rxjs/operators';
import { reportInternalError } from '@app/utils';
import { take } from 'rxjs';

@Injectable()
export class AwsRumService {
  private readonly DEFAULT_APPLICATION_VERSION = '1.0.0';
  private readonly DEFAULT_APPLICATION_REGION = 'eu-central-1';

  constructor(private readonly configService: ConfigService) {}

  initializeAwsRum(): void {
    this.configService
      .getConfig$()
      .pipe(
        filter((val) => !!val),
        take(1),
      )
      .subscribe({
        next: (config: IConfig) => {
          const awsRumConfig: AwsRumConfig = {
            sessionSampleRate: 1,
            endpoint: config.awsRumEndpoint,
            guestRoleArn: config.awsRumGuestRoleArn,
            telemetries: ['errors', 'http', 'performance'],
            allowCookies: false,
            enableXRay: false,
            identityPoolId: config.awsRumIdentityPoolId,
            sessionEventLimit: 25,
          };

          if(env.production) {
            this.createAwsRumInstance(
              config.awsRumApplicationId,
              awsRumConfig,
              this.DEFAULT_APPLICATION_VERSION,
              this.DEFAULT_APPLICATION_REGION,
            );
          }
        },
        error: (error) => {
          reportInternalError('Error initializing AWS RUM: ' + error);
        },
      });
  }

  private createAwsRumInstance(
    applicationId: string,
    awsRumConfig: AwsRumConfig,
    applicationVersion: string,
    applicationRegion: string,
  ): AwsRum {
    return new AwsRum(
      applicationId,
      applicationVersion,
      applicationRegion,
      awsRumConfig,
    );
  }
}

@NgModule({
  providers: [
    AwsRumService,
    {
      provide: APP_INITIALIZER,
      useFactory: (awsRumService: AwsRumService) => () =>
        awsRumService.initializeAwsRum(),
      deps: [AwsRumService],
      multi: true,
    },
  ],
})
export class AwsRumModule {}
