import { AuthHelperService, ConfigService } from '@app/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, HostListener, OnInit } from '@angular/core';

import { CommonModule } from '@angular/common';
import { Constants } from '@app/utils';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
 
import { Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';

@Component({
  selector: 'app-global-header',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatToolbarModule,
    MatCardModule,
    MatIconModule,
    MatSnackBarModule,
  ],
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss'],
})
export class GlobalHeaderComponent implements OnInit {
  user$: Observable<User>;
  isAuthenticated$: Observable<boolean>;

  isProfileEnabled$: Observable<boolean>;
  isUserManagementEnabled$: Observable<boolean>;
  showMenusFromSideNav: boolean = false;
  private readonly screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
  protected showInAppNotifications$: Observable<boolean>;

  constructor(
    private readonly authHelper: AuthHelperService,
    config: ConfigService,
    private readonly router: Router
  ) {
    this.isAuthenticated$ = this.authHelper.isAuthenticated$;
    this.user$ = authHelper.getUserProfile$();
    this.isProfileEnabled$ = config.isFeatureEnabled$('profile', false);
    this.isUserManagementEnabled$ = config.isFeatureEnabled$(
      'userManagement',
      false,
    );
    this.showInAppNotifications$ = config.isFeatureEnabled$(
      Constants.appNotificationsFlag,
      false,
    );
  }

  ngOnInit(): void {
    this.getScreenWidth().subscribe((width: number) => {
      this.showMenusFromSideNav = width <= Constants.breakpointMedium - 1;
    });
  }

  navigateToRoute(route: string) {
    this.router.navigate([route]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const window = event.target as Window;
    this.screenWidth$.next(window.innerWidth);
  }

  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }

  logout(): void {
    this.authHelper.logout();
  }
}
