import { Component, OnDestroy  } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthHelperService } from '@app/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnDestroy {
  canvasWidth = 100;
  canvasHeight = 100;

  isAuthenticated$: Observable<boolean>;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private authHelper: AuthHelperService,
  ) {
    this.isAuthenticated$ = this.authHelper.isAuthenticated$;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
