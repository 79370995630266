/*
 * This class defines all communication between this application and Auth0.
 */
import { AuthService, User } from '@auth0/auth0-angular';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { Constants, reportInternalError } from '@app/utils';
import { Injectable, OnDestroy } from '@angular/core';

import { UserRole } from '@app/models';

const ACCESS_EXPIRY = 'expiry';
const ACCESS_TOKEN = 'access';

export interface Tenant {
  name: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthHelperService implements OnDestroy {
  private readonly userRole$ = new BehaviorSubject<UserRole | undefined>(undefined);
  private readonly userProfile$ = new BehaviorSubject<User | undefined>(undefined);
  private readonly ngUnsubscribe = new Subject<void>();
   
  private readonly tenantsSubject$ = new BehaviorSubject<Array<Tenant> | undefined>(
    undefined,
  );
  // Contains list of all tenants.
  tenants$ = this.tenantsSubject$.asObservable();

  // stores all errors from auth0
  private readonly errors$: Observable<Error | null | undefined>;

  isLoading$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;

  constructor(private readonly auth: AuthService) {
    this.isLoading$ = this.auth.isLoading$;
    this.isAuthenticated$ = this.auth.isAuthenticated$;
    this.errors$ = this.auth.error$;

    this.auth.idTokenClaims$.pipe(
      tap((claim) => {
        if (claim?.exp) {
          sessionStorage.setItem(ACCESS_EXPIRY, claim.exp.toString());
          claim.__raw && sessionStorage.setItem(ACCESS_TOKEN, claim.__raw);
        }
      }),
    );

    this.auth.user$
      .pipe(
        filter((profile) => !!profile),
        map((profile) => profile as User),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((profile) => {
        this.userProfile$.next(profile);

        // extract list of tenants from profile
        this.loadTenantsFromProfile(profile);

        const currentRole = this.userRole$.value;
        const tenantRoles = this.parseTenantRoles(profile);
        if (tenantRoles === undefined || tenantRoles.length === 0) {
          reportInternalError('Failed to find any tenant roles.');
          return;
        }

        if (!currentRole && tenantRoles.length > 0) {
          this.userRole$.next(
            new UserRole(
              tenantRoles[0].role,
              '',
              '',
              tenantRoles[0].team,
              tenantRoles[0].user_role_id,
              tenantRoles[0].tenant_id,
            ),
          );
        }
      });
  }

  getSelectedTenant$(): Observable<Tenant> {
    const userRole = this.userRole$.value;
    const tenants = this.tenantsSubject$.value;

    return of(tenants?.find((t) => t.id === userRole?.tenantId) as Tenant);
  }

  getErrors$(): Observable<Error> {
    return this.errors$.pipe(
      filter((err) => !!err),
      map((err) => err as Error),
    );
  }

  generateAccessToken$(): Observable<string> {
    this.clearCachedToken();
    return this.auth.getAccessTokenSilently();
  }

  getUserRole$(): Observable<UserRole> {
    return this.userRole$.pipe(
      filter((userRole) => !!userRole),
      // need this map to satisfy TS type checking
      map((userRole) => userRole as UserRole),
    );
  }

  getUserProfile$(): Observable<User> {
    return this.userProfile$.pipe(
      filter((user) => !!user),
      map((user) => user as User),
    );
  }

  login(): void {
    this.clearCachedToken();
    this.auth.loginWithRedirect();
  }

  logout(): void {
    this.clearCachedToken();
    this.auth.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  setUserRole(userRoleId: string) {
    const profile = this.userProfile$.value;

    const tenantRoles = this.parseTenantRoles(profile as User);
    if (tenantRoles === undefined || tenantRoles.length === 0) {
      reportInternalError('Failed to set selected user role.');
      return;
    }

    const newRole = tenantRoles.find((r) => r.user_role_id === userRoleId);

    this.userRole$.next(
      new UserRole(
        newRole.role,
        '',
        '',
        newRole.team,
        newRole.user_role_id,
        newRole.tenant_id,
      ),
    );
  }

  getCachedAccessToken$(): Observable<string | null> {
    return new Observable((obs) => {
      const token = sessionStorage.getItem(ACCESS_TOKEN);
      const expiry = sessionStorage.getItem(ACCESS_EXPIRY);

      if (!token || !expiry) {
        // We don't have a cached token or expiry is null.
        obs.next(null);
        obs.complete();

        return;
      }

      const now = new Date();
      const secondsToExpire = +expiry - now.getTime() / 1000;
      if (secondsToExpire < 55) {
        // This token will expire soon so don't use it.
        // If we ask for a new one with more than 1 minute remaining
        // then we would get the same one back, so wait
        // until only 55 seconds remain.
        obs.next(null);
        obs.complete();
        return;
      }

      obs.next(token);
      obs.complete();
    });
  }

  requestNewAccessToken$(): Observable<string> {
    return this.generateAccessToken$().pipe(
      filter((token) => !!token),
      tap((token) => sessionStorage.setItem(ACCESS_TOKEN, token)),
      map((token) => token as string),
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private parseTenantRoles(profile: User): Array<any> | undefined {
    const tenantRoleStr = profile?.[Constants.authz];
    if (!tenantRoleStr) return;

    return JSON.parse(tenantRoleStr) as Array<any>;
  }

  private clearCachedToken(): void {
    sessionStorage.removeItem(ACCESS_EXPIRY);
    sessionStorage.removeItem(ACCESS_TOKEN);
  }

  private loadTenantsFromProfile(profile: any) {
    const tenants = profile[Constants.tenants];

    if (tenants) {
      const tenantList = JSON.parse(tenants).map((tenant: any) => {
        return {
          id: tenant.tenant_id,
          name: tenant.display_name,
        } as Tenant;
      });

      this.tenantsSubject$.next(tenantList);
    }
  }

  getAccessToken$(): Observable<string> {
    return this.getCachedAccessToken$().pipe(
      switchMap((res) => {
        if (res === null || res === undefined)
          return this.requestNewAccessToken$();
        return of(res);
      }),
    );
  }
}
