export class ApiQueries {
  static readonly changePassword: string = `
    mutation passwordChange(
      $selected_user_role_id: ID!, $credentials: Credentials!
    ) {
      passwordChange(
        selected_user_role_id: $selected_user_role_id, credentials: $credentials
      ) {
        data {
          ... on Error {
            status_code
            error
            message
            error_code
          }
        }
        status_code
      }
    }
  `;

  static readonly getFeatureFlags: string = `
    query getFeatureFlags(
      $selected_user_role_id: ID!,
      $feature_flag_names: [String!]
    ) {
      getFeatureFlags(
        selected_user_role_id: $selected_user_role_id,
        feature_flag_names: $feature_flag_names) {
        data {
          ... on FeatureFlags {
            featureFlags {
              featureFlagName
              status
            }
          }
          ... on Error {
            status_code
            error
            message
            error_code
          }
        },
        status_code
      }
    }
  `;
}
