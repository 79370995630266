import * as THREE from 'three';
import { Injectable } from '@angular/core';

/**
 * A simple service that provides a method to obtain
 * a new THREE.WebGLRenderer instance.
 *
 * The purpose of this service is so that we have full
 * control over mocking it out, as headless chrome
 * has issues mocking WebGL contexts. This way, we can
 * just provide a mock implmentation for createRenderer.
 *
 * This has been excluded from SonarCloud for the exact
 * reason that we cannot mock THREE.WebGLRenderer directly
 */
@Injectable({
  providedIn: 'root',
})
export class RendererFactoryService {
  createRenderer(
    parameters?: THREE.WebGLRendererParameters,
  ): THREE.WebGLRenderer {
    return new THREE.WebGLRenderer(parameters);
  }
}
