/*
 * Enums
 */

export enum Role {
  Unspecified = '',
  ClassAdmin = 'class.admin',
  AppAdmin = 'app.admin',
  TeamAdmin = 'team.admin',
  User = 'user',
}

export enum TeamId {
  Invalid = 'abcxyz',
  Unspecified = '',
}

export enum UserId {
  Invalid = 'xyzabc',
  Unspecified = '',
}

/*
 * Classes
 */

export class Client {
  iid: string;
  cid: string;
  name: string;
  teams: Team[] = [];

  constructor(cid: string, iid: string, name: string) {
    this.cid = cid;
    this.iid = iid;
    this.name = name;
  }
}

export class Team {
  tid: string; // slug
  name: string;

  constructor(tid: string, name: string) {
    this.tid = tid;
    this.name = name;
  }

  asString(): string {
    return `${this.tid}:${this.name}`;
  }

  static fromString(input: string): Team {
    const halves = input.split(':', 2);
    return new Team(halves[0], halves[1]);
  }
}

export class User {
  uid: string;
  givenName: string;
  familyName: string;
  email: string;
  roles: UserRole[] = [];
  displayRole: string = '';

  constructor(
    uid: string,
    givenName: string,
    familyName: string,
    email: string,
    displayRole: string
  ) {
    this.uid = uid;
    this.givenName = givenName;
    this.familyName = familyName;
    this.email = email;
    this.displayRole = displayRole
  }

  getRelevantRole(cid: string, iid: string, tid: string): UserRole | undefined {
    return this.roles.find(
      (r) => !r.isExpired && r.cid === cid && r.iid === iid && r.tid === tid,
    );
  }
}

export class UserRole {
  rid: string; // role id
  cid: string; // class id
  iid: string; // instance (app) id
  tid: string; // team id (slug)
  role: string; // from Role enum
  isExpired = false;
  tenantId: string; // tenant id

  constructor(
    role: string,
    cid: string = '',
    iid: string = '',
    tid: string = '',
    rid: string = '',
    tenantId: string = '',
  ) {
    this.role = role;
    this.cid = cid;
    this.iid = iid;
    this.tid = tid;
    this.rid = rid;
    this.tenantId = tenantId;
  }
}
