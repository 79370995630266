import { environment as env } from '@env/environment';

export function reportInternalError(message: string) {
  Report.internalError(message);
}

export class Report {
  private static log(message: string): void {
    if (!env.production) {
       
      console.log(message);
       
      console.trace();
    }
  }

  public static internalError(message: string): void {
    Report.log(`ERROR: ${message}`);
  }

  public static deprecation(message: string, reason: string): void {
    Report.log(`DEPRECATED [${reason}]: ${message}`);
  }
}
