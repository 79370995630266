/*
 * This class is used to convert the models from the backend endpoints
 * into models used in this app.
 */

import {
  ApiClientError,
  ApiServerError,
  ERR_NO_FUNCTION_RESULT,
  ERR_NO_INNER_DATA,
  ERR_NO_OUTER_DATA,
  ERR_NO_STATUS_CODE,
  ERR_UNRECOGNIZED_STATUS,
  ParserError
} from '@app/models';
import {
  FeatureFlags,
} from '@app/services';
import { reportInternalError } from '@app/utils';

/**
 * This function performs two checks:
 * 1. Type Check: Verifies if the type of the value matches the
 * expected type using `typeof`.
 * 2. Null Check: Ensures the value is not null,
 * unless `allowNull` is set to true.
 *
 * If the validation fails:
 * - For missing properties (e.g., if `channel.ChannelName` does not exist),
 *  `getValidateType('ChannelName', channel, 'string')`
 *   will return undefined because `typeof undefined` is not 'string'.
 * - The function logs an error with the property name,
 *   expected type, and actual value.
 *
 * @param propertyName - The name of the property being validated.
 * @param values - The object containing the property to validate.
 * @param expectedType - The expected type of the property as a string.
 * @param allowNull - Optional.
 * A boolean indicating whether null values are allowed (default is false).
 * @returns The value of the property if it matches the expected type
 * and the null check passes, otherwise undefined.
 */
export function getValidatedValue<T>(
  propertyName: string,
  values: any,
  expectedType: string,
  allowNull: boolean = false,
): T | undefined {
  const value = values[propertyName];

  if (value === null && allowNull) {
    return value as T;
  }

  if (typeof value !== expectedType) {
    ApiInputParser.logTypeError(propertyName, expectedType, value);
    return undefined;
  }

  return value as T;
}

export function getValidatedArray<T>(
  propertyName: string,
  values: any,
): T | undefined {
  if (!Array.isArray(values[propertyName])) {
    ApiInputParser.logTypeError(propertyName, 'array', values[propertyName]);
    return undefined;
  }
  return values[propertyName] as T;
}

export class ApiInputParser {
  private static sanitizeResponse(functionName: string, result: any): any {
    const outerData = result.data;
    if (!outerData) throw new ParserError(ERR_NO_OUTER_DATA);

    const functionResult = outerData[functionName];
    if (!functionResult)
      throw new ParserError(ERR_NO_FUNCTION_RESULT + functionName);

    const status = functionResult.status_code;
    if (!status) throw new ParserError(ERR_NO_STATUS_CODE);

    if (!functionResult.hasOwnProperty('data'))
      throw new ParserError(ERR_NO_INNER_DATA);

    const innerData = functionResult.data;
    // some functions (expire user role) may return no data
    if (innerData === null && status === 204) return null;

    const { error, error_code, message, status_code } = innerData;

    if (status >= 500)
      throw new ApiServerError(status_code, error_code, error, message);

    if (status >= 400 && status < 500)
      throw new ApiClientError(status_code, error_code, error, message);

    if (status > 205) throw new ParserError(ERR_UNRECOGNIZED_STATUS + status);

    return innerData;
  }

  static logTypeError(
    propertyName: string,
    expectedType: string,
    actualValue: any,
  ) {
    reportInternalError(
      `Invalid type for ${propertyName}. Expected ${expectedType},
    but got ${typeof actualValue}: ${actualValue}`,
    );
  }

  static parseChangePasswordResult(result: any): boolean {
    this.sanitizeResponse('passwordChange', result);
    return true;
  }

  static parseFeatureFlagsResult(result: any): FeatureFlags {
    const data = this.sanitizeResponse('getFeatureFlags', result);
    return ApiInputParser.parseFeatureFlagsData(data.featureFlags);
  }

  /**
   * Parses the feature flag data
   * @param data an array of objects containing featureFlagName and its status
   * @returns an object where the key is featureFlagName and value is status
   */
  static parseFeatureFlagsData(
    data: { featureFlagName: string; status: boolean }[],
  ): FeatureFlags {
    const result: FeatureFlags = {};
    return data.reduce((prev, curr) => {
      prev[curr.featureFlagName] = curr.status;
      return prev;
    }, result);
  }
}
