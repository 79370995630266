/*
 * This class defines all communication between this front end and
 * the backend API service.
 * It is responsible for formatting the HTTP requests and parsing the responses.
 * It should not perform any business level logic because that is handled by the
 * DataService class.
 */

import {
  ApiClientError,
  ApiServerError
} from '@app/models';
import { Apollo, gql } from 'apollo-angular';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  finalize,
  forkJoin,
  map,
  of,
  retry,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import { ConfigService, IConfig } from '../config.service';
import { Constants, reportInternalError } from '@app/utils';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { ApiInputParser } from './api-input-parser';
import { ApiQueries } from './api-queries';
import { ApolloLink } from '@apollo/client/core';
import { AuthHelperService } from '../auth-helper.service';
import { HttpLink } from 'apollo-angular/http';
 
import { WebSocketFactoryService } from '../websocket/websocket-factory.service';
import { WebSocketSubject } from 'rxjs/webSocket';
import { setContext } from '@apollo/client/link/context';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private webSocket$?: WebSocketSubject<unknown>;

  private readonly notifications$ = new BehaviorSubject<
    Notification | undefined
  >(undefined);
  private readonly wsErrorsB$ = new BehaviorSubject<Error | null>(null);
  private readonly lastKa$ = new Subject<void>();
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly apollo: Apollo,
    private readonly authSvc: AuthHelperService,
    private readonly configSvc: ConfigService,
    private readonly httpLink: HttpLink,
    private readonly wsFactory: WebSocketFactoryService,
  ) {}

  getWebsocketNotifications$(): Observable<Notification | undefined> {
    return this.notifications$.asObservable();
  }

  getWebsocketErrors$(): Observable<Error | null> {
    return this.wsErrorsB$.asObservable();
  }

  // can't mock properties that are used on lifecycle start.
  // need a method.
  private getRetryDelayMS(): number {
    return 5000;
  }

  // can't mock properties that are used on lifecycle start.
  // need a method.
  private getKaTimeoutMS(): number {
    return 65000;
  }

  private getKa$(): Observable<any> {
    // when a ka message is received, start
    // a 1 minute timer that will be reset when the next ka message
    // is recieved. If the timer expires, the socket is closed.
    return this.lastKa$.pipe(
      switchMap(() => {
        return timer(this.getKaTimeoutMS()).pipe(
          tap(() => {
            this.webSocket$?.complete();
          }),
          switchMap(() => {
            return EMPTY;
          }),
        );
      }),
    );
  }

  private startWebSocket$(): Observable<any> {
    return forkJoin([
      this.authSvc.getUserProfile$().pipe(take(1)),
      this.authSvc.getAccessToken$(),
      this.authSvc.getUserRole$().pipe(take(1)),
      this.configSvc.getConfig$().pipe(take(1)),
    ]).pipe(
      switchMap(([profile, token, role, config]) => {
        const authHeader = this.createAuthHeader(
          token,
          config.notificationGraphqlUri,
          role?.rid,
        );
        this.webSocket$ = this.getWebSocketSubject(config, authHeader);

        // Handle the subject observable with retry logic and backoff
        return this.webSocket$.pipe(
          tap({
            next: (msg: any) => {
              if (!profile.sub) return;

              if (msg.type === 'connection_ack') {
                this.wsErrorsB$.next(null);
              } else if (msg.type === 'ka') {
                // reset ka timer
                this.lastKa$.next();
              }
            },
          }),
          retry({
            count: 3,
            delay: this.getRetryDelayMS(),
          }),
          catchError(() => {
            return EMPTY;
          }),
          finalize(() => {
            this.wsErrorsB$.next(new Error('Websocket disconnected.'));
          }),
        );
      }),
    );
  }

  private getWebSocketSubject(
    config: IConfig,
    authHeader: object,
  ): WebSocketSubject<unknown> {
    const encodedHeaders = btoa(JSON.stringify(authHeader));
    const emptyPayload = btoa(JSON.stringify({}));

    return this.wsFactory.makeSocket({
       
      url: `${config.notificationWebsocketUri}?header=${encodedHeaders}&payload=${emptyPayload}`,
      protocol: 'graphql-ws',
      openObserver: {
        next: () => {
          this.webSocket$!.next({ type: 'connection_init' });
        },
      },
      closeObserver: {
        next: () => {},
      },
    });
  }

  private createAuthHeader(
    token: string,
    host: string,
    roleId: string,
  ): Object {
    return {
      Authorization: `Bearer ${token}`,
      host: `${host.replace('https://', '').replace('/graphql', '')}`,
      selected_user_role_id: roleId,
    };
  }

  private readonly handleError = (functionName: string, error: Error) => {
    // this is our first chance to catch an exception
    // and possibly ignore it or morph it into something else
    let errorDetails;
    switch (error.constructor) {
      case ApiServerError:
        // the details of this will not matter the user
        errorDetails = 'ApiServerError()';
        break;

      case ApiClientError: {
        // this might matter to the user or to a higher level of logic
        const { status, code, summary, message } = error as ApiClientError;
        errorDetails =
          `ApiClientError(${status}, "${code}",` +
          ` "${summary}", "${message}")`;
        break;
      }

      default:
        // this is a ParserError or other unexpected error
        // the details won't matter to the user
        errorDetails = `${error.name}()`;
    }
    reportInternalError(
      `ApiService.${functionName}() caught ${errorDetails}; rethrowing`,
    );

    throw error;
  };

  setNewPassword$(password: string): Observable<boolean> {
    return forkJoin([
      this.authSvc.getUserRole$().pipe(take(1)),
      this.authSvc.getAccessToken$(),
      this.configSvc.getConfig$().pipe(take(1)),
      this.configSvc
        .isFeatureEnabled$(Constants.changePasswordFlag, false)
        .pipe(take(1)),
    ]).pipe(
      switchMap(([roles, accessToken, config, changePasswordFeature]) => {
        if (!changePasswordFeature) {
          return of(false);
        }

        const variables = {
          selected_user_role_id: roles?.rid,
          credentials: { newCredentials: password },
        };

        // set Authorization header
        this.setApolloHeaderLink(accessToken, config.atdGraphqlUri);

        return this.apollo
          .mutate({
            mutation: gql`
              ${ApiQueries.changePassword}
            `,
            variables,
          })
          .pipe(
            map((result: any) =>
              ApiInputParser.parseChangePasswordResult(result),
            ),
            catchError((error) => of(false)),
          );
      }),
    );
  }

  // Adds the jwt to the request headers
  private setApolloHeaderLink(accessToken: string, uri: string) {
    const basic = setContext((operation, context) => ({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }));

    // Create an apollo link with the token and uri
    const apolloHeaderLink = ApolloLink.from([
      basic,
      this.httpLink.create({ uri }),
    ]);

    this.apollo.client.setLink(apolloHeaderLink);
  }

  getFeatureFlags$(
    featureFlagIds: string[],
  ): Observable<{ [key: string]: boolean }> {
    return forkJoin([
      this.authSvc.getUserRole$().pipe(take(1)),
      this.authSvc.getAccessToken$(),
      this.configSvc.getConfig$().pipe(take(1)),
    ]).pipe(
      switchMap(([roles, accessToken, config]) => {
        this.setApolloHeaderLink(accessToken, config.atdGraphqlUri);

        const variables = {
          selected_user_role_id: roles?.rid,
          feature_flag_names: featureFlagIds,
        };

        return this.apollo
          .watchQuery({
            query: gql`
              ${ApiQueries.getFeatureFlags}
            `,
            variables,
          })
          .valueChanges.pipe(
            map((result) => ApiInputParser.parseFeatureFlagsResult(result)),
            catchError((error) => {
              return this.handleError('getFeatureFlags$', error);
            }),
          );
      }),
    );
  }
}
