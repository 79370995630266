export class Constants {
  // the auth0 property that contains the user's roles
  static readonly authz = 'https://humanetics.com/tenant_roles';
  static readonly tenants = 'https://humanetics.com/tenants';

  static readonly millisecondsPerDay = 24 * 60 * 60 * 1000;

  // the fleet-page size property that controls how many ATDs
  // need to be loaded and displayed on the landing page by default.
  static readonly defaultPageSize = 8;
  static readonly largeScreenDefaultPageSize = 12;
  static readonly maxPageSize = 16;
  static readonly testResultsPageSize = 8;
  static readonly maxSelectableTestResults = 5;
  static readonly iconPaths = {
    pdfDownload: 'assets/icons/pdf-download.svg',
  };
  static readonly notificationThresholdHoursMap: Record<string, string> = {
    '1008': '6 Weeks',
    '720': '30 Days',
    '672': '4 Weeks',
    '336': '2 Weeks',
    '168': '1 Week',
    '48': '2 Days',
    '24': '1 Day',
    '0': 'Today',
    '-336': '2 Weeks',
    undefined: 'No Data',
  };
  // minimum date supported by the backend
  static readonly minDate = new Date(1970, 0, 1);
  static readonly breakpointSmall = 1024;
  static readonly breakpointMedium = 1280;
  static readonly breakpointLarge = 1440;
  static readonly breakpointXLarge = 1920;
  static readonly mobileBreakpointMedium = 906;
  static readonly mobileBreakpointSmall = 701;
  static readonly mobileBreakpointXSmall = 426;

  // dynamic feature flag ids
  static readonly emailNotificationsFlag = 'email-notifications';
  static readonly appNotificationsFlag = 'app-notifications';
  static readonly certificationReportsFlag = 'certification-reports';
  static readonly mockedCertificationReportsFlag =
    'certification-reports-mocked';
  static readonly changePasswordFlag = 'change-password';
  static readonly graphsFlag = 'graphs';
  static readonly interactive3dFlag = 'interactive-3d';

  static readonly featureFlagIds = [
    this.emailNotificationsFlag,
    this.appNotificationsFlag,
    this.certificationReportsFlag,
    this.mockedCertificationReportsFlag,
    this.changePasswordFlag,
    this.graphsFlag,
    this.interactive3dFlag,
  ];
}
