import {
  WebSocketSubject,
  WebSocketSubjectConfig,
  webSocket,
} from 'rxjs/webSocket';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WebSocketFactoryService {
  constructor() {}

  public makeSocket<T>(
    urlConfigOrSource: string | WebSocketSubjectConfig<T>,
  ): WebSocketSubject<T> {
    return webSocket<T>(urlConfigOrSource);
  }
}
