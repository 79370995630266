/*
 * This service provides data to and from the UI
 * and acts as a proxy to the API service.
 */

import { ApiClientError, ApiServerError} from '@app/models';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  map,
  takeUntil,
} from 'rxjs';
import { reportInternalError } from '@app/utils';
import { Injectable, OnDestroy } from '@angular/core';

import { ApiService } from './api/api.service';
import { FilterCriteria } from './data.service.types';

export interface FilterData {
  [key: string]: {
    name: string;
    options: string[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class DataService implements OnDestroy {
  private readonly filterList$ = new BehaviorSubject<FilterData | undefined>(undefined);
  private readonly filterCriteria$ = new BehaviorSubject<FilterCriteria>({});
  private readonly fleetsize$ = new BehaviorSubject<number>(0);
  private readonly isLoadingFleets$ = new BehaviorSubject<boolean>(false);

  // notifies subscriptions for clean up.
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(private readonly api: ApiService) {
    this.loadInitialData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        
      });
  }

  /**
   * Loads initial data for the component. This method retrieves a list
   * of ATD objects, performs necessary data transformations, initializes
   * observables, and sets up filter options for display.
   *
   * @returns An observable that emits a completion signal after
   * loading the initial data successfully.
   *
   * @throws An error observable in case of API service failure.
   */
  private loadInitialData(): Observable<any> {
    this.isLoadingFleets$.next(true);
    return new Observable<any>()
  }

  getFleetSize$(): Observable<number> {
    return this.fleetsize$.asObservable();
  }

  getIsLoadingFleets$(): Observable<boolean> {
    return this.isLoadingFleets$.asObservable();
  }

  setNewPassword$(password: string): Observable<boolean> {
    return this.api.setNewPassword$(password);
  }

  setFilterCriteria(filters: FilterCriteria) {
    this.filterCriteria$.next(filters);
  }

  getFilters$(): Observable<FilterData> {
    return this.filterList$.asObservable().pipe(
      filter((fd) => !!fd),
      map((fd) => fd as FilterData),
    );
  }

  private readonly passthroughErrorHandler = (functionName: string, error: any) => {
    // this is another chance to catch an exception
    // and possibly ignore it or morph it into something else
    const errorDetails =
      error instanceof ApiClientError || error instanceof ApiServerError
        ? `${error.status}, "${error.code}",` +
          ` "${error.summary}", "${error.message}"`
        : error.message;

    reportInternalError(
      `DataService.${functionName}() caught ${error.name}(${errorDetails})` +
        '; rethrowing',
    );
    throw error;
  };

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private includesSubstring(value: string, searchTerm: string): boolean {
    return (
      !!value &&
      (!searchTerm || value.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }
}
