<mat-toolbar>
  <button
    id="app-toolbar-home"
    (click)="navigateToRoute('/')"
    class="app-borderless"
    aria-label="Home button"
    data-testid="global-header-home"
  >
    <div class="app-toolbar-logo"></div>
  </button>

  <div class="header-row">
    <ng-container *ngIf="(isAuthenticated$ | async) && (user$ | async) as user">

      <mat-menu #menu="matMenu">
        <mat-card class="app-borderless">
          <mat-card-content
            [ngClass]="{ 'side-nav-menu-buttons': showMenusFromSideNav }"
          >
            <button
              *ngIf="showMenusFromSideNav"
              mat-menu-item
              id="app-toolbar-fleet-view"
              (click)="navigateToRoute('fleet')"
              class="app-borderless app-menuitem"
              aria-label="ATD Fleet"
              data-testid="global-header-fleet"
            >
              <span class="app-toolbar-menu-text"> Fleet View </span>
            </button>

            <button
              *ngIf="showMenusFromSideNav"
              mat-menu-item
              id="app-toolbar-support-view"
              (click)="navigateToRoute('support')"
              class="app-borderless app-menuitem"
              aria-label="Support"
              data-testid="global-header-support"
            >
              <span class="app-toolbar-menu-text"> Support </span>
            </button>

            <button
              *ngIf="isProfileEnabled$ | async"
              mat-menu-item
              id="app-toolbar-profile"
              (click)="navigateToRoute('profile')"
              class="app-borderless app-menuitem"
              aria-label="Profile button"
              data-testid="global-header-profile"
            >
              <span
                *ngIf="!showMenusFromSideNav"
                class="inline-icon material-symbols-outlined"
              >
                account_circle
              </span>
              <span class="app-toolbar-menu-text"> My profile </span>
            </button>

            <button
              *ngIf="isUserManagementEnabled$ | async"
              mat-menu-item
              id="app-toolbar-user-management"
              (click)="navigateToRoute('**')"
              class="app-borderless app-menuitem"
              aria-label="Profile button"
              data-testid="global-header-admin"
            >
              <span
                *ngIf="!showMenusFromSideNav"
                class="inline-icon material-symbols-outlined"
              >
                people_alt
              </span>
              <span class="app-toolbar-menu-text"> User Management </span>
            </button>

            <button
              mat-menu-item
              id="app-toolbar-logout"
              (click)="logout()"
              class="app-borderless app-menuitem"
              aria-label="Sign out button"
              data-testid="global-header-logout"
            >
              <span
                *ngIf="!showMenusFromSideNav"
                class="inline-icon material-symbols-outlined"
              >
                logout
              </span>
              <span class="app-toolbar-menu-text"> Log out </span>
            </button>
          </mat-card-content>
        </mat-card>
      </mat-menu>
    </ng-container>
  </div>
</mat-toolbar>
