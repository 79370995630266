import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment as env } from '@env/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { reportInternalError } from '@app/utils';

if (env.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => reportInternalError(err));
