import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ConfigService } from '@app/services';
import {
  GlobalHeaderComponent
} from '@app/widgets';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { filter, lastValueFrom, take } from 'rxjs';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from '@auth0/auth0-angular';
import { AwsRumModule } from '@app/aws-rum.module';
import { BrowserModule } from '@angular/platform-browser';
import { GraphQLModule } from './graphql.module';
import { MatNativeDateModule } from '@angular/material/core';

export function initializeApp(
  appConfigService: ConfigService,
) {
  return async (): Promise<any> => {
    await lastValueFrom(
      appConfigService
        .getConfig$()
        .pipe(filter((val) => !!val))
        .pipe(take(1)),
    );
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot(),
    AwsRumModule,
    BrowserModule,
    GlobalHeaderComponent,
    GraphQLModule,
    MatNativeDateModule
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService, HttpClient],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi())
  ],
})
export class AppModule {}
